import styled from "styled-components";

import { CategoriesMenuStyledProps as Props } from "./CategoriesMenu.types";
import CONSTANTS from "../../../config/constants";

const { BREAKPOINTS } = CONSTANTS;

const { tablet } = BREAKPOINTS;

const CategoriesMenuStyled = styled.div<Props>`
  display: flex;
  background-color: var(--palette-primary);
  align-items: center;
  color: var(--palette-white);
  height: 5.8rem;
  display: grid;
  grid-template-columns: var(--sizes-page-columns);
  grid-template-areas: ". content .";

  @media (max-width: ${tablet}px) {
    justify-content: space-between;
  }

  .CategoriesMenu {
    &__content {
      grid-area: content;
      display: flex;
      align-items: center;
      color: var(--palette-white);
      height: 5.6rem;
      justify-content: center;
    }

    &__left {
      grid-area: left;
      height: 5.8rem;
      background-color: var(--palette-primary);
      margin-right: -0.1rem;

      @media (max-width: ${tablet}px) {
        display: none;
      }
    }

    &__item {
      height: inherit;
      display: inherit;
      align-items: center;
      cursor: pointer;
      font-weight: 600;
      width: 100%;
      justify-content: center;

      svg {
        margin-right: 0.8rem;
      }
    }

    &__divider {
      height: 2rem;
      background-color: var(--palette-white);
      width: 0.1rem;
      opacity: 0.3;
      margin: 0 3.2rem;
    }
  }
`;

export default CategoriesMenuStyled;
