import styled from "styled-components";

import { TabsMenuStyledProps as Props } from "./TabsMenu.types";

const TabsMenuStyled = styled.div<Props>`
  .TabsMenu {
    &__tabs {
      padding-top: 2rem;
    }

    &__tabs {
      &__item {
        min-width: 6rem;
      }
    }

    &__bar {
      padding-bottom: 0.8rem;
    }
  }
`;

export default TabsMenuStyled;
