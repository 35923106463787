import { useFetchCategories } from "@sushicorp/services";
import { goToCategory } from "@sushicorp/utils";
import Link from "next/link";
import React from "react";

import Styles from "./CategoriesMenu.styles";
import { CategoriesMenuProps as Props } from "./CategoriesMenu.types";
import CONSTANTS from "config/constants";
import useI18n from "hooks/useI18n";
import { notify } from "utils/common.utils";

import MenuSVG from "/public/assets/images/menu.svg";
import PromoSVG from "/public/assets/images/promotions.svg";

const { PROMOTION_CATEGORY } = CONSTANTS.GENERAL;

const CategoriesMenu: React.FC<Props> = props => {
  const { className } = props;
  const { data: categories } = useFetchCategories(notify);
  const t = useI18n();

  /**
   * Redirection to category promotions.
   *
   * @since 1.0.0
   */
  const goToPromotion = () => {
    const promotionCategory = categories?.find(
      category => `${category.categoryId}` === PROMOTION_CATEGORY
    );
    if (!promotionCategory) return;
    goToCategory(promotionCategory);
  };

  return (
    <Styles className={`CategoriesMenu ${className}`}>
      <div className="CategoriesMenu__content">
        <div className="CategoriesMenu__left" />
        <Link href="/categories" passHref>
          <div className="CategoriesMenu__item">
            <MenuSVG />
            {t.home.menu}
          </div>
        </Link>
        <div className="CategoriesMenu__divider" />
        <div className="CategoriesMenu__item" onClick={goToPromotion}>
          <PromoSVG />
          {t.home.promotions}
        </div>
      </div>
    </Styles>
  );
};

CategoriesMenu.defaultProps = {};

export default CategoriesMenu;
