import styled from "styled-components";

import { ProductGridPlaceholderStyledProps as Props } from "./ProductGridPlaceholder.types";
import CONSTANTS from "config/constants";

const { BREAKPOINTS } = CONSTANTS;
const { tablet } = BREAKPOINTS;

const ProductGridPlaceholderStyled = styled.div<Props>`
  display: grid;
  width: 100%;
  justify-items: center;
  grid-template-columns: repeat(auto-fill, minmax(30rem, 1fr));
  gap: 3.2rem;
  overflow: hidden;
  background-color: var(--palette-white);

  .ProductCardPlaceholder {
    &__image {
      @media (max-width: ${tablet}px) {
        width: 100%;
        height: 20rem;
      }
    }

    @media (max-width: ${tablet}px) {
      width: 100%;
    }
  }
  .ProductGridPlaceholder {
  }
`;

export default ProductGridPlaceholderStyled;
