import React from "react";

import Styles from "./ProductGridPlaceholder.styles";
import { ProductGridPlaceholderProps as Props } from "./ProductGridPlaceholder.types";
import ProductCardPlaceholder from "../ProductCard/ProductCard.placeholder";

const ProductGridPlaceholder: React.FC<Props> = props => {
  const { quantity, className } = props;

  return (
    <Styles className={`ProductGridPlaceholder ${className}`}>
      {Array(quantity)
        .fill("")
        .map((_, i) => (
          <ProductCardPlaceholder key={i} />
        ))}
    </Styles>
  );
};

ProductGridPlaceholder.defaultProps = {
  className: ""
};

export default ProductGridPlaceholder;
