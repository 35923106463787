import { useCountries, useProducts, useStores } from "@sushicorp/contexts";
import { goToProductDetail } from "@sushicorp/utils";
import { toSentenceCaseUtility as toSentence } from "@sushicorp/utils";
import { getImage } from "@sushicorp/utils";
import { events } from "artisn/analytics";
import { AdditionalInfo, BaseProduct } from "artisn/types";
import { Image, ProductCard as ProductCardUI } from "artisn-ui-react";
import { useProductCard } from "artisn-ui-react";
import React, { useCallback, useEffect, useMemo, useRef } from "react";

import ProductCardPlaceholder from "./ProductCard.placeholder";
import Styles from "./ProductCard.styles";
import { ProductCardProps as Props } from "./ProductCard.types";
import ProductBasePriceInfo from "../ProductBasePriceInfo/ProductBasePriceInfo";
import CONSTANTS from "config/constants";
import useAnalytics from "contexts/analytics/analytics.context.hooks";
import useI18n from "hooks/useI18n";

const { Description, Name } = ProductCardUI;
const { logProductImpression, logSelectProduct } = events.product;
const { WITH_PRODUCT_MODAL } = CONSTANTS.FEATURE_FLAGS;
const { PRODUCT_FALLBACK_IMAGE } = CONSTANTS.GENERAL;

const ProductCard: React.FC<Props> = props => {
  const { width = 216, height = 328, icon, className } = props;
  const { product: productCard } = props;
  const { carousel = false } = props;
  const { setOpenProductModal } = useProducts();
  const t = useI18n();
  const { decimals } = useCountries().selectedCountry;
  const { totals, available } = useProductCard(productCard, { decimals });
  const { selectedStore } = useStores();
  const { isOpen } = selectedStore ?? {};
  const { analyticsInitialized } = useAnalytics();
  const productCardRef = useRef<HTMLDivElement>(null);
  const product: BaseProduct<AdditionalInfo> = useMemo(
    () => ({
      ...productCard,
      name: toSentence(productCard.name),
      description: toSentence(productCard.description)
    }),
    [productCard]
  );
  const enhancedProduct = !carousel
    ? { ...product, images: [product.images[0]] }
    : product;
  const { url } = getImage(enhancedProduct.images, PRODUCT_FALLBACK_IMAGE);
  const { attributes } = enhancedProduct;
  const { showInMenu } = attributes;

  /**
   * Function to log product when user observe.
   *
   * @since 1.0.0
   * @param {IntersectionObserverEntry[]} entries The entries of the observer.
   */
  const callback = useCallback<IntersectionObserverCallback>(
    entries => {
      const [entry] = entries;
      if (!entry.isIntersecting || !analyticsInitialized) return;
      logProductImpression({
        product
      });
    },
    [analyticsInitialized, product]
  );

  /**
   * Function to open product modal.
   *
   * @since 1.0.0
   */
  const onClickHandler = () => {
    logSelectProduct({
      product
    });
    if (WITH_PRODUCT_MODAL) {
      setOpenProductModal(enhancedProduct.productId);
    } else {
      goToProductDetail(enhancedProduct);
    }
  };

  useEffect(() => {
    const productCard = productCardRef.current;
    if (!productCard) return;

    const observer = new IntersectionObserver(callback, { threshold: 0.7 });
    observer.observe(productCard);

    return () => observer.unobserve(productCard);
  }, [callback]);

  if (isOpen && (!showInMenu || !available)) return null;

  return (
    <Styles
      ref={productCardRef}
      className={`ProductCard ${className}`}
      available={available}
    >
      <ProductCardUI
        className="ProductCard__card"
        product={enhancedProduct}
        placeholder={<ProductCardPlaceholder />}
        width={`${width}px`}
        height={`${height}px`}
        onClick={onClickHandler}
      >
        <Image
          className="ProductCard__image"
          image={url}
          alt={toSentence(enhancedProduct.name)}
          errorImage="/assets/images/fallback-kobe.png"
        />
        <ProductBasePriceInfo
          className="ProductCard__price"
          productTotals={totals}
        />
        <Name className="ProductCard__name" />
        <Description className="ProductCard__description" />
        {icon}
        <button className="ProductCard__button">{t.cart.add}</button>
      </ProductCardUI>
    </Styles>
  );
};

ProductCard.defaultProps = {
  className: ""
};

export default ProductCard;
